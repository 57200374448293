/** @define Slider */
$componentClass: '.Slider';
.Slider {
    position: relative;
    &-pagination {
      margin-top: $global-padding;
      z-index:1;
      text-align: center;

      > .swiper-pagination-number {
        position: relative;
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 100%;
        &:not(:last-child)::before {
          content: '';
          position: absolute;
          top: 50%;
          right: -100%;
          width: 15px;
          height: 2px;
          transform: translateY(-50%);
          background-color: $color-gray100;
        }
        &::after {
          content: attr(data-count);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          color: $color-white;
          text-align: center;
          font-size: .6em;
          line-height: 15px;
        }
      }
      > button.swiper-pagination-number {
        border: none;
        margin: 0;
        padding: 0;
        box-shadow: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }

      > .swiper-pagination-number,
      > .swiper-pagination-bullet {
        margin: 0 rem-calc(5);
        background: $color-gray15;
        opacity: 1;
        transition: all $timing-duration $timing-easing;
        outline: 0;
        &.swiper-pagination-bullet-active {
            background: $gradient-primary;
        }
      }
    }
    &-buttons {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      z-index: 2;
      @include media($media_s) {
        display: none;
      }

      .swiper-button-disabled {
        opacity: 0;
      }
    }
    &-buttonPrev,
    &-buttonNext {
      position: absolute;
      top: 0;
      cursor: pointer;
      pointer-events: all;
      width: rem-calc(34);
      height: rem-calc(65);
      padding: rem-calc(20) rem-calc(10);
      background-color: $color-gray15;
      border-radius: $border-radius-s;
      transition: all $timing-duration $timing-easing;
      .Icon-base {
        fill: $color-gray100;
        transition: all $timing-duration $timing-easing;
      }
      &:hover {
        background-color: $color-gray50;
        .Icon-base {
          fill: $color-gray-dark;
        }
      }
    }

    &-buttonPrev {
      left: 0;
      transform: rotate(180deg);
    }

    &-buttonNext {
      right: 0;
    }

    &--light {
      #{$componentClass}-pagination {
        .swiper-pagination-number,
        .swiper-pagination-bullet {
          background-color: $color-black;
          &.swiper-pagination-number-active,
          &.swiper-pagination-bullet-active {
            background: $gradient-primary;
          }
        }
      }
    }
    &--dark {
      #{$componentClass}-pagination {
        .swiper-pagination-number,
        .swiper-pagination-bullet {
          background-color: $color-gray100;
          &.swiper-pagination-number-active,
          &.swiper-pagination-bullet-active {
            background-color: $color-primary;
          }

          &.swiper-pagination-number-complete {
            background-color: $color-primary;
            &::before {
              background-color: $color-primary;
            }
          }
        }
      }

      #{$componentClass}-buttons {
        .Slider-buttonPrev,
        .Slider-buttonNext {
          background-color: #333;
          .Icon-base {
            fill: $color-gray50;
          }
          &:hover {
            .Icon-base {
              fill: $color-primary;
            }
          }
        }
      }
    }

    &--aligned {
      #{$componentClass}-buttonPrev,
      #{$componentClass}-buttonNext {
        top: 50%;
        transform-origin: 50% 50%;
      }

      #{$componentClass}-buttonPrev {
        transform: translateY(-50%) rotate(180deg);
      }

      #{$componentClass}-buttonNext {
        transform: translateY(-50%);
      }
    }

    &--centered {
      #{$componentClass}-wrapper {
        justify-content: center;
      }

      #{$componentClass}-slide:last-child {
        margin-right: 0 !important;
      }
    }

  .slider-pagination-copy {
    font-size: 24px;
    @include media($media_xs) {
      font-size: 16px;
    }
  }

  .slider-pagination-price {
    @include media($media_xs) {
      font-size: 12px;
    }
  }
}
