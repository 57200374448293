@import 'normalize.css/normalize';
@import 'swiper/swiper-bundle';
@import 'rc-slider/dist/rc-slider';
@import 'react-smartbanner/dist/main';

// Dont use height 100% or overflow-x.
// Position: sticky dose not work when either are set
html {
  box-sizing: border-box;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

* {
  &,
  &:after,
  &:before {
    box-sizing: border-box;
  }
}

body {
  font-family: $font-family-regular;
  > img, > iframe {
    display: none !important;
  }

  iframe {
    border: none;
  }
}

html.no-scroll {
  body {
    overflow: hidden;
  }
}

em {
  font-style: normal;
  color: $color-primary;
}

strong {
  font-weight: $font-weight-bold;
}

dl {
  margin: 0;
  & > dt,
  & > dd {
    width: 50%;
    margin: 0;
    display: inline-block;
    box-sizing: border-box;
  }
  & > dt {
    font-weight: bold;
  }
}

ul {
  padding:0;
  list-style-type: none;
  text-indent: 0;
  // chrome/webkit useragent style resets
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

summary {
  cursor: pointer;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $color-primary;
  // disallow outline on negative tab indexes
  &[tabindex^="-"] {
    outline: none;
  }
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

select::-ms-expand {
  display: none;
}

sup[title] {
  cursor: pointer;
}

// size / line-height same as .TextCopy-secondary
small {
  font-size: $copy-small_font-size;
  line-height: $copy-small_line-height;
}

// For Transparent Autofill in Webkit Browser
@keyframes webkit-auto-fill {
  to {
    color: $color-primary;
    background: transparent;
  }
}

input, textarea {
  appearance: none;
  border:none;
  border-radius: 0;
  background: transparent;
  &::-ms-reveal,
  &::-ms-clear {
    width: 0;
    height: 0;
  }
  &:-webkit-autofill {
    -webkit-animation-name: webkit-auto-fill;
    -webkit-animation-fill-mode: both;
  }
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.icon-fill-primary{fill:$color-primary;}

img {
  vertical-align:top;
}

[data-color-mode="dark"]:before {
  content: '';
  background: $color-black-darkmode;
  max-height:100vh;
  position: absolute;
  width: 100%;
  height: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
