// Mixins
// - - - - - - - - - - - - - - - - - - - - - - - - -

// BewTextStyling

@use "sass:math";

@mixin bewTextStyling ($color, $font-size, $font-family, $letter-spacing, $line-height) {
  color: $color;
  font-size: $font-size;
  font-family: $font-family;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
}

// TextHeadline Mixins

@mixin headlineFormat($type, $margin-top: $h_top-margin, $margin-bottom: $h_bottom-margin) {
  @if  $type == 'subline' {
    @include headline(
      $font: $subline_font-family,
      $size: $subline_font-size,
      $weight: $h_font-weight,
      $line-height: $subline_line-height
    );
  } @else if $type == 'subheading' {
    @include headline(
      $font: $subheading_font-family,
      $size: $subheading_font-size,
      $weight: $h_font-weight,
      $line-height: $subheading_line-height,
      $spacing: $subheading_letter-spacing
    );
  }
}

@mixin headline(
  $font: $h_font-family,
  $size: $h-m_font-size,
  $weight: $h_font-weight,
  $line-height: $h-m_line-height,
  $margin-top: $h_top-margin,
  $margin-bottom: $h_bottom-margin,
  $spacing: $h_letter-spacing,
  $color: inherit
) {
  font-family: $font;
  font-size: $size;
  font-weight:$weight;
  line-height: $line-height;
  letter-spacing: $spacing;
  text-transform: uppercase;
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
  color: $color;
  overflow-wrap: break-word;

  @if $size == $h-xxl_font-size {
    @include media($media_s-m) {
      font-size: $h-xl_font-size;
      line-height: $h-xl_line-height;
    }
    @include media($media_s) {
      font-size: $h-l_font-size;
      line-height: $h-l_line-height;
    }
  }

  @if $size == $h-xl_font-size {
    @include media($media_s) {
      font-size: $h-l_font-size;
      line-height: $h-l_line-height;
    }
  }

  @if $size == $h-l_font-size {
    @include media($media_s) {
      font-size: $h-m_font-size;
      line-height: $h-m_line-height;
    }
  }

  @if $size == $h-m_font-size {
    @include media($media_s) {
      font-size: $h-s_font-size;
      line-height: $h-s_line-height;
    }
  }

  @if $size == $h-s_font-size {
    @include media($media_s) {
      font-size: $h-xs_font-size;
      line-height: $h-xs_line-height;
    }
  }
}

// TextCopy Mixins
@mixin copyTextFormat($type: null, $margin-top:$copy_top-margin, $margin-bottom:$copy_bottom-margin) {
  @if not $type {
    @include copyText( $size: $copy_font-size, $line-height: $copy_line-height, $margin-top: $margin-top, $margin-bottom: $margin-bottom);
  }
  @else if $type=='secondary' {
    @include copyText( $size: $copy-secondary_font-size, $line-height: $copy-secondary_line-height, $spacing: $copy-secondary_letter-spacing, $margin-top: $margin-top, $margin-bottom: $margin-bottom);
  }
  @else if $type=='tertiary' {
    @include copyText( $font: $copy-tertiary_font-family, $size: $copy-tertiary_font-size, $line-height: $copy-tertiary_line-height, $margin-top: $margin-top, $margin-bottom: $margin-bottom);
  }
  @else if $type=='small' {
    @include copyText( $size: $copy-small_font-size, $line-height: $copy-small_line-height, $margin-top: $margin-top, $margin-bottom: $margin-bottom);
  }
}

@mixin copyText( $font:$copy_font-family, $size:$copy_font-size, $line-height:$copy_line-height, $spacing:$copy_letter-spacing, $margin-top:$copy_top-margin, $margin-bottom:$copy_bottom-margin, $padding: 0, $color: inherit) {
  font-family: $font;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $spacing;
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
  padding: $padding;
  color: $color;
  text-decoration: none;
}

// TextLink Mixins
@mixin Button(
  $context: "TextLink",
  $decendentText: "text",
  $decendentIcon: "icon",
  $stateNoLabel: "buttonWithoutLabel",
  $stateFilled: "buttonFilled",
  $typeTertiary: "tertiary",
  $typeHighlight: "highlight",
  $colorLight: "light",
  $colorDark: "dark",
  $isThemeDark: false,
) {
  position: relative;
  display: inline-block;
  font-family: $font-family-condensed;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: rem-calc(23);
  color: $color-primary;
  background-color: transparent;
  border-radius: $border-radius-m;
  cursor: pointer;
  padding: 0.5em 1em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  transition: color $timing-duration $timing-easing,
              border-color $timing-duration $timing-easing;

  &:not(.#{$context}--#{$stateFilled}) {
    border: 2px solid $color-primary;

    &::before, &::after {
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: -2px;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    width: auto;
    border-radius: $border-radius-m;
  }

  &::after {
    left: 50%;
    right: 50%;
  }

  &:hover,
  &:focus  {
    transition: color $timing-duration * .5 $timing-easing $timing-duration * .5,
                border-color $timing-duration * .5 $timing-easing $timing-duration * .5;
  }

  & > span {
    pointer-events: none;
  }

  .#{$context}-#{$decendentText} {
    position: relative;
    z-index: 1;
    &::after {
      top: 0.0654em;
      font-weight: $font-weight-bold;
    }
  }

  &.#{$context}--#{$stateNoLabel} {
    color: $color-black;

    .#{$context}-#{$decendentText} {
      display: none;
    }
    &::after {
      display: inline-block!important;
      content: ">"!important;
      position: relative;
      top: 0.025em;
      left: auto;
      right: auto;
      width: auto;
      margin-left: 0!important;
      font-weight: bold;
      font-size: 1.5em;
      height: auto;
      background: none;
      color: inherit;
      animation: none;
    }

    &::before {
      background: $gradient-primary;
      transition: background $timing-duration $timing-easing;
    }

    &:hover, &:focus, &.is-active {
      &::before {
        background: $gradient-primary-hover;
      }

      &::after {
        background: none;
        animation: none;
      }
    }
  }

  &.#{$context}--#{$stateFilled}:not(.#{$context}--#{$stateNoLabel}) {
    color: $color-black;

    &::before {
      background: $gradient-primary;
    }

    &:after {
      animation: ani-button-filled-leave $timing-duration $timing-easing forwards;
    }

    &:hover, &:focus, &.is-active {
      &:after {
        margin: 0 rem-calc(-2);
        opacity: 0;
        animation: ani-fade-in $timing-duration $timing-easing $timing-duration * .25 forwards,
                   ani-button-filled-enter $timing-duration * .75 $timing-easing $timing-duration * .25 forwards;
      }
    }

    &.#{$context}--#{$colorLight} {
      color: $color-white;
    }

    &.#{$context}--#{$typeTertiary} {
      color: $color-white;
      background: $gradient-tertiary;

      &::before {
        background: $gradient-tertiary;
      }

      &.#{$context}--#{$colorDark} {
        color: $color-black;
      }
    }

    &.#{$context}--#{$typeHighlight} {
      color: $color-black;
      background: $gradient-secondary;

      &::before {
        background: $gradient-secondary;
      }
    }

    .#{$context}-#{$decendentText} {
      &::after {
        color: inherit;
      }
    }
  }

  &:not(.#{$context}--#{$stateFilled}) {
    color: $color-primary;

    &::before,
    &::after {
      display: none;
      animation: none;
    }

    &:hover, &:focus, &.is-active {
      color: $color-primary-dark;
      border-color: $color-primary-dark;
      transition: color $timing-duration $timing-easing, border-color $timing-duration $timing-easing;

      &::after {
        animation: none;
      }
    }

    &.#{$context}--#{$colorLight} {
      color: $color-white;
      border-color: $color-white;

      &:hover, &:focus, &.is-active {
        border-color: $color-white;
      }
    }

    &.#{$context}--#{$colorDark} {
      color: $color-black;
      border-color: $color-black;

      &:hover, &:focus, &.is-active {
        border-color: $color-black;
      }
    }

    .#{$context}-#{$decendentText} {
      &::after {
        color: inherit;
      }
    }
  }



  &.is-disabled, &.is-disabled:hover {
    cursor: default;
    border-color: $color-gray15 !important;
    color: $color-gray15 !important;
    background-color: transparent !important;
    transition: none !important;
    animation: none !important;
    &::after, &::before {
      transition:  none !important;
      animation:  none !important;
      background: transparent !important;
    }

    .Icon-base, .Icon-highlight {
      fill: $color-gray15;
    }
  }

  &.is-loading, &.is-loading:hover {
    cursor: default;
    .#{$context}-#{$decendentText},
    .#{$context}-#{$decendentIcon} {
      opacity: 0;
    }
  }

  @if $isThemeDark == true {
    &.is-disabled, &.is-disabled:hover {
      border-color: $color-gray-light-darkmode !important;
      color: $color-gray-light-darkmode !important;
    }
  }
}

@mixin TabButton {
  color: inherit;
  display: inline-block;
  font-size: 18px;
  background: none;
  border: none;
  text-transform: uppercase;
  font-family: $font-family-condensed;
  transition: all $timing-duration $timing-easing;

  &:after{
    content: '';
    display: block;
    width: 0;
    margin-left:50%;
    height: 4px;
    background-color: $color-primary;
    transition: all $timing-duration $timing-easing;
  }

  &.is-active {
    color: $color-primary;
    &:after{
      width: 100%;
      margin-left:0;
    }
  }
}

@mixin Link(
  $context: "TextLink",
  $decendentText: "text",
  $stateArrow: "arrow",
  $stateNoStyling: false,
  $colorLight: "light"
) {
  position: relative;
  display: inline-block;
  max-width: 100%;
  font-family: $font-family-regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-primary;
  letter-spacing: 0.02em;
  text-decoration: none;
  transition: color $timing-duration $timing-easing;


  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: auto;
    background: rgba($color-primary-dark, 0);
    animation: ani-textlink-leave $timing-duration $timing-easing forwards;
  }

  &:hover, &:focus, &.is-active{
    cursor: pointer;
    color: $color-primary-dark;
    transition: color $timing-duration * .6 $timing-easing $timing-duration * .4;
    &::after {
      animation: ani-textlink-enter $timing-duration * .75 $timing-easing $timing-duration * .25 forwards;
    }
  }

  &--#{$stateArrow} {
    .#{$context}-#{$decendentText} {
      &::after {
        content: ">" / "";
        position: relative;
        top: 0.1em;
        margin-left: 0.15em;
        font-weight: normal;
        font-size: 1.275em;
        color: inherit;
        font-family: $font-family-condensed;
      }

      &::before, &::after {
        transition: color $timing-duration $timing-easing;
      }
    }

    &.u-arrowLeft {
      .#{$context}-#{$decendentText} {
        &::before {
          content: "<";
          position: relative;
          top: 0.1em;
          margin-right: 0.15em;
          font-weight: normal;
          font-size: 1.275em;
          color: inherit;
          font-family: $font-family-condensed;
        }

        &::after {
          content:none;
        }
      }
    }

    &:hover, &:focus, &.is-active {
      .#{$context}-#{$decendentText} {
        &::before, &::after {
          transition: color $timing-duration * .5 $timing-easing $timing-duration * .5;
        }
      }
    }
  }

  &.u-colorDefault {
    .#{$context}-#{$decendentText} {
      &::before, &::after {
        color: $color-black;
      }
    }

    &:hover, &:focus, &.is-active {
      .#{$context}-#{$decendentText} {
        &::before, &::after {
          color: $color-primary-dark;
        }
      }
    }
  }

  &--#{$colorLight} {
    color: $color-white;
  }
}

// Default styling for inline links
@mixin RawTextLink {
  text-decoration: none;
  color: $color-primary;
  border-bottom: 1px solid rgba($color-primary-dark, 0);
  transition: color $timing-duration $timing-easing,
              border-color $timing-duration ease-in;
  &:hover, &:focus {
    color: $color-primary-dark;
    border-color: rgba($color-primary-dark, 1);
  }
}

@mixin DefaultTextLink {
  text-decoration: none;
  transition: color $timing-duration ease-out;
}

@mixin NavigationTextLink {
  font-family: $font-family-condensed;
  color: $color-black;
  font-size: rem-calc(18);
  font-weight: $font-weight-bold;
  line-height: 1.2rem;
  letter-spacing: rem-calc(0.11);
  text-transform: uppercase;
  transition: color $timing-duration * .75 $timing-easing;

  &:after {
    display: none;
  }

  .Icon-base {
    fill: $color-black;
  }
}

// ul list formatter - bullets / checkmarks
@mixin listFormater($checkmarks: false, $inherit: false, $size: 7px, $isThemeDark: false){
  $themeLight: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#{$color-black}" cx="5" cy="5" r="5"/></svg>');
  $themeDark: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#{$color-white}" cx="5" cy="5" r="5"/></svg>');
  $width: $size;
  $height: $size;
  $padding-left: 1em * math.div(strip-unit($size), 7);
  $top: rem-calc(strip-unit($size));

  @if $checkmarks == true {
    $themeLight: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-primary}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
    $themeDark: $themeLight;
    $width: 15px;
    $height: 15px;
    $padding-left: 1.35em;
    $top: rem-calc(6);
  }

  @if $inherit == true {
    ul {
      li { padding-left: $padding-left; }
      li::before {
        top: $top;
        width: $width;
        height: $height;
        background-image: $themeLight;
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
    &.u-colorInverted, &--inverted {
      ul > li::before {
        background-image: $themeDark;
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
  }

  @if $inherit == false {
    ul {
      margin: 0;
      padding-left: 0;
      list-style: none;
      li {
        position: relative;
        padding-left: $padding-left;
        // add padding instead of margin to prevent alignment issues with multi column layouts
        padding-bottom: $global-padding * .25;
        break-inside: avoid;
      }

      li::before {
        content:"";
        position: absolute;
        left: 0;
        top: $top;
        // IE11 fix - use pixel values for sizing
        width: $width;
        height: $height;
        line-height: 0;
        background-image: $themeLight;
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
    &.u-colorInverted, &--inverted {
      ul > li::before {
        background-image: $themeDark;
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
  }

  @if $isThemeDark == true {
    ul > li::before {
      background-image: $themeDark;
      background-position: left top;
      background-repeat: no-repeat;
    }
  }
}

@mixin notificationCount(
  $size: rem-calc(17),
  $fontSize: rem-calc(12),
  $color: $color-white,
  $background-color: $color-primary
){
  display: block;
  width: $size;
  height: $size;
  font-size: $fontSize;
  font-weight: bold;
  line-height: $size;
  text-align: center;
  color: $color;
  background: $background-color;
  border-radius: 50%;
}


@mixin borderDashed(
    $color: $color-gray100,
    $top: false,
    $right: false,
    $bottom: false,
    $left: false,
    $size: 2px
){
    border: $size dotted $color;

    $borderSizeTop: $size;
    $borderSizeRight: $size;
    $borderSizeBottom: $size;
    $borderSizeLeft: $size;

    @if $top == false {
      border-top:0;
      $borderSizeTop:0;
    }

    @if $right == false {
      border-right:0;
      $borderSizeRight:0;
    }

    @if $bottom == false {
      border-bottom:0;
      $borderSizeBottom:0;
    }

    @if $left == false {
      border-left:0;
      $borderSizeLeft:0;
    }

    border-image-source:svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><rect fill="#{$color}" x="6" y="3" width="2" height="2"/><rect fill="#{$color}" x="0" y="3" width="2" height="2"/> <rect fill="#{$color}" x="0" y="0" width="2" height="2"/> <rect fill="#{$color}" x="0" y="6" width="2" height="2"/> <rect fill="#{$color}" x="3" y="6" width="2" height="2"/> <rect fill="#{$color}" x="6" y="6" width="2" height="2"/> <rect fill="#{$color}" x="6" y="0" width="2" height="2"/> <rect fill="#{$color}" x="3" y="0" width="2" height="2"/> </svg>');
    border-image-width: $borderSizeTop $borderSizeRight $borderSizeBottom $borderSizeLeft;
    border-image-slice: 2;
    border-image-repeat: round;
    // @todo FIREFOX 50.0.2 Workaround - dosnt display border image. Use default dotted image instead until real fix is found
    -moz-border-image: none;
}

@mixin outerContainer($maxWidth: 100%){
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

// Bourbon Neat 1.7.4
@mixin media($query: $feature $value) {
  $loop-to: length($query) - 1;
  $media-query: "screen and ";
  $i: 1;
  @while $i <= $loop-to {
    $media-query: $media-query + "(" + nth($query, $i) + ": " + nth($query, $i + 1) + ") ";
    @if ($i + 1) != $loop-to {
      $media-query: $media-query + "and ";
    }
    $i: $i + 2;
  }

  @media #{$media-query} {
    @content;
  }
}

/* grid utility mixins */

@mixin column($col: '', $gutter: null, $breakpoint: '', $maxColumns: $grid-columns) {
  $strict: '';
  $class: '.u-col#{$col}';
  @if $gutter != null { $strict: '>'; }
  @if $breakpoint != '' { $class: '.u-#{$breakpoint}Col#{$col}'; }
  #{$strict}#{$class} {
    @if $gutter != null {
      @if $col != '' {
        width: calc(#{percentage(math.div($col, $maxColumns))} - #{$gutter});
      } @else {
        flex: 1;
      }
      margin-left: $gutter;
      margin-top: $gutter;
    } @else if $col != '' {
      width: percentage(math.div($col, $maxColumns));
    }
  }
}

@mixin collapseCollumn($col: '', $gutter: null, $breakpoint: '') {
  $class: '> .u-col#{$col}';
  @if $breakpoint != '' { $class: '> .u-#{$breakpoint}Col#{$col}'; }
  #{$class} {
    @if $gutter != null {
      width: calc(100% - #{$gutter});
    } @else {
      width: 100%;
    }
  }
}

@mixin collapse($gutter: null, $breakpoint: null) {
  flex-direction: column;
  flex-wrap: nowrap;

  @if $gutter != null {
    margin-top: initial;
    margin-left: initial;
    > *  {
      margin-left: initial;
      &:first-child {
        margin-top: initial;
      }
    }
  }

  > [class^="u-col"], > [class*=" u-col"],
  > [class^="u-sCol"], > [class*=" u-sCol"],
  > [class^="u-mCol"], > [class*=" u-mCol"],
  > [class^="u-smCol"], > [class*=" u-smCol"],
  > [class^="u-mlCol"], > [class*=" u-mlCol"],
  > [class^="u-lCol"], > [class*=" u-lCol"] {
    width: 100%;
  }
}

@mixin globalPadding(
    $horizontal: true,
    $vertical: true,
    $topRatio: 1,
    $bottomRatio: 1,
    $leftRatio: 1,
    $rightRatio: 1,
    $queries: true
){

  $top: $global-padding * $topRatio;
  $bottom: $global-padding * $bottomRatio;
  $left: $global-padding * $leftRatio;
  $right: $global-padding * $rightRatio;

  @if $horizontal == true and $vertical == true {

    @if $queries == true {
      padding: $top rem-calc(10) $bottom;
      @include media($media_m) {
        padding: $top $left $bottom $right;
      }
      @include media($media_l) {
        padding: $top 0 $bottom;
      }
    }

    @if $queries == false {
      padding: $top $right $bottom $left;
    }
  }

  @if $horizontal == true and $vertical == false {

    @if $queries == true {
      padding: 0 rem-calc(10);
      @include media($media_m) {
        padding: 0 $left 0 $right;
      }
      @include media($media_l) {
        padding: 0;
      }
    }

    @if $queries == false {
      padding: 0 $right 0 $left;
    }
  }

  @if $horizontal == false and $vertical == true {
    padding: $top 0 $bottom;
  }
}

@mixin spacing(
  $left: false,
  $right: false,
  $top: false,
  $bottom: false,
  $horizontal: false,
  $vertical: false,
  $type: 'margin',
  $ratio: 1
){
  $spacing: $global-padding * $ratio;
  @if $horizontal == true and $vertical == true {
    #{$type}: $spacing !important;
  } @else if $horizontal == true and $vertical == false {
    #{$type}-left: $spacing !important;
    #{$type}-right: $spacing !important;
  } @else if $horizontal == false and $vertical == true {
    #{$type}-top: $spacing !important;
    #{$type}-bottom: $spacing !important;
  } @else {
    @if $left == true {
      #{$type}-left: $spacing !important;
    }
    @if $right == true {
      #{$type}-right: $spacing !important;
    }
    @if $top == true {
      #{$type}-top: $spacing !important;
    }
    @if $bottom == true {
      #{$type}-bottom: $spacing !important;
    }
  }
}

/*
* Display-type utility mixins
* 1. Fix for Firefox bug: an image styled `max-width:100%` within an
* inline-block will display at its default size, and not limit its width to
* 100% of an ancestral container.
*
* 2. Completely remove from the flow but leave available to screen readers.
*/

@mixin displayBlock() {
  display: block !important;
  @include wrap($flex: false)
}

@mixin displayInlineBlock() {
  display: inline-block !important;
  max-width: 100%; /* 1 */
  @include wrap($flex: false)
}

@mixin displayInline() {
  display: inline !important;
  @include wrap($flex: false)
}

@mixin displayFlex() {
  display: flex !important;
  @include wrap($flex: true);
}

@mixin displayRow() {
  display: flex;
  flex-wrap: wrap;

  @include media($media_s) {
    &.u-sCollapse { @include collapse(); }
  }

  @include media($media_m) {
    &.u-mCollapse { @include collapse(); }
  }

  @include media($media_s-m) {
    &.u-smCollapse { @include collapse(); }
  }

  @include media($media_m-l) {
    &.u-mlCollapse { @include collapse(); }
  }

  @include media($media_l) {
    &.u-lCollapse { @include collapse(); }
  }

}

@mixin displayHidden() {
  display: none !important;
}

@mixin displayHiddenVisually() {
  /* 2 */
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

@mixin position($position: 'relative') {
  @if $position == 'sticky' {
    @include positionSticky;
  } @else {
    position: #{$position};
  }
}

@mixin positionSticky() {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 0;
}

@mixin wrap($flex: false) {
  @if ($flex) {
    &.u-wrap { flex-wrap: wrap; }
    &.u-nowrap { flex-wrap: nowrap; }
    &.u-sWrap { flex-wrap: wrap; }
    &.u-sNowrap { flex-wrap: nowrap; }
    &.u-smWrap { flex-wrap: wrap; }
    &.u-smNowrap { flex-wrap: nowrap; }
    &.u-mWrap { flex-wrap: wrap; }
    &.u-mNowrap { flex-wrap: nowrap; }
    &.u-mlWrap { flex-wrap: wrap; }
    &.u-mlNowrap { flex-wrap: nowrap; }
    &.u-lWrap { flex-wrap: wrap; }
    &.u-lNowrap { flex-wrap: nowrap; }
  } @else {
    &.u-wrap { white-space: normal; }
    &.u-nowrap { white-space: nowrap; }
    &.u-sWrap { white-space: normal; }
    &.u-sNowrap { white-space: nowrap; }
    &.u-smWrap { white-space: normal; }
    &.u-smNowrap { white-space: nowrap; }
    &.u-mWrap { white-space: normal; }
    &.u-mNowrap { white-space: nowrap; }
    &.u-mlWrap { white-space: normal; }
    &.u-mlNowrap { white-space: nowrap; }
    &.u-lWrap { white-space: normal; }
    &.u-lNowrap { white-space: nowrap; }
  }
}

@mixin flexDirection($direction: 'row') {
  flex-direction: #{$direction};
  @if $direction == 'column' {
    @include flexWrap('nowrap');
  }
}

@mixin flexJustifyContent ($position: 'flex-start') {
  justify-content: #{$position};
}

@mixin flexAlignItems ($position: 'stretch') {
  align-items: #{$position};
}

@mixin flexAlignContent ($position: 'stretch') {
  align-content: #{$position};
}

@mixin flexAlignSelf ($position: 'stretch') {
  align-content: #{$position};
}

@mixin flexWrap ($value) {
  flex-wrap: #{$value};
}

@mixin elementMarkBefore($svg) {
  /*@todo introduce parameter width, background-position background-size*/
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin-right: .3em;
    background-image: svg-url($svg);
    background-position: left top;
    background-repeat: no-repeat;
  }
}

@mixin elementInfoIAfter($fill-color: $color-default) {
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin-left: .2em;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g><rect class="Icon-base" x="8.96" y="8.7" width="2.1" height="6.52" fill="#{$fill-color}"/> <path class="Icon-base" d="M10,4.78A1.17,1.17,0,0,0,8.82,6,1.16,1.16,0,0,0,10,7.14a1.22,1.22,0,0,0,.89-0.36A1.14,1.14,0,0,0,11.18,6,1.15,1.15,0,0,0,10,4.78Z" fill="#{$fill-color}"/> <path class="Icon-highlight" stroke-width="0" d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18.54A8.54,8.54,0,1,1,18.54,10,8.55,8.55,0,0,1,10,18.54Z" fill="#{$fill-color}"/></g></svg>');
    background-repeat: no-repeat;
    position: relative;
    top: -.1em;
  }
}

@mixin elementMarked($color: $color-primary-highlight) {
  position: relative;

  &::after {
    content: "";
    background: $color;
    width: calc(100% + #{math.div($global-padding, 6)});
    height: 100%;
    position: absolute;
    top: 0;
    left: -#{math.div($global-padding, 6)};
    z-index: 0;
    border-radius: $border-radius-m;
    pointer-events: none;
  }

  ul & {
    &::after {
      width: calc(100% + #{$global-padding});
      left: -$global-padding;
    }
  }
}
