/** @define FAQ */
$componentClass: '.FAQ';

.FAQ {
  width: 100%;
  margin-block-start: 0;
  margin: 0 0 $global-padding * .25 0;
  background-color: $color-gray15;

  &-title {
    padding: rem-calc(10) $global-padding;
    font-family: $font-family-condensed;
    font-size: rem-calc(18);
    text-transform: uppercase;
  }

  &-subtitle {
    padding: rem-calc(10) $global-padding * .5;
    font-family: $font-family-condensed;
    font-size: rem-calc(18);
    text-transform: uppercase;
  }

  &-body {
    padding: 0 $global-padding * .5 $global-padding * .5 $global-padding * .5;
  }

  &-subtitle {
    background-color: white;
  }

  &-subtitle:not(:last-child) {
    margin-bottom: $global-padding * .25;
  }

  &-groupBody {
    background-color: white;
    padding: 0 $global-padding * .5 0 $global-padding * .5;
  }

  &-entry {
    padding: $global-padding * .5 0 $global-padding * .5 $global-padding;
  }

  &-entry:not(:last-child) {
    @include borderDashed($color-gray15, false, false, true);
  }

  &-answer{
    padding: $global-padding * .5 0 0 0;
  }

  &-main--forceOpen > &-title {
    pointer-events: none;
  }

  summary {
    justify-content: space-between;
    list-style: none;
    display: flex;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::after {
    content: '';
    width: rem-calc(12);
    height: rem-calc(19);
    flex-shrink: 0;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    transform-origin: center center;
    transition: all $timing-duration $timing-easing;
  }

  &-main--forceOpen > &-title::after {
    background: none;
  }

  &-title::after {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
  }

  &-subtitle::after {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon class="Icon-base" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13 "/></svg>');
  }

  &-question::after {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon class="Icon-base" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13 "/></svg>');
  }



  details[open] > &-title,
  &-main--forceOpen > &-title {
    color: $color-primary;
  }

  details[open] > &-title::after {
    transform: rotate(180deg);
  }

  details[open] > &-subtitle::after {
    background-image: svg-url('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" ><polygon class="Icon-base" points="22,0 0,0 0,4 22,4 "/></svg>');
    transform: rotate(180deg);
  }

  details[open] > &-question::after {
    background-image: svg-url('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" ><polygon class="Icon-base" points="22,0 0,0 0,4 22,4 "/></svg>');
    transform: rotate(180deg);
  }
}

