/** @define TariffPanel */
.TariffItemPanel {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  height:100%;
  width: 100%;

  &-header {
    width: 100%;
    background-color: $color-gray15;
    padding: $global-padding $global-padding $global-padding*0.5 $global-padding;
    margin-bottom: $global-padding;
    min-height: rem-calc(108px);
    overflow: hidden;

    .TariffItemPanel-singlePaymentFee,
    .TariffItemPanel-paymentFee {
      text-align: right;
      color: $color-black;
    }

    @include media($media_s-m) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include media($media_m-l) {
      max-height: rem-calc(108);
    }

    @include media($media_l) {
      padding: $global-padding $global-padding;
    }
  }

  &-content {
    flex: 1 0 auto;
    width: 70%;
    overflow: hidden;
    align-self: stretch;
    padding: 0 $global-padding 0 $global-padding;
    @include media($media_m) {
      flex: 1 0 auto;
      width: 100%;
    }
    @include media($media_l) {
      flex: 1 0 auto;
      width: 60%;
      @include borderDashed($color-gray50, false, true);
    }

    .TariffItemPanel-copy {
      margin-top: $global-padding * .25;
    }
  }

  &-productInformation {
    margin-top: rem-calc(16);
    text-decoration: underline;
    letter-spacing: 0.03px;

    &::after {
      content: none;
    }

    @include media($media_m) {
      margin-bottom: rem-calc(8);
    }

    @include media($media_s) {
      margin-top: rem-calc(8);
    }
  }

  &-subcontent {
    padding: 0 $global-padding 0 0;
    @include media($media_s-m) {
      align-self: flex-end;
    }

    @include media($media_m-l) {
      padding: 0 $global-padding 0 $global-padding;
    }

    @include media($media_m) {
      margin-top: $global-padding * .25;
    }

    @include media($media_l) {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      min-height: rem-calc(180);
    }
  }
  &-title {
    @include media($media_s-m) {
      width: 60%;
      flex-shrink: 0;
    }
    @include media($media_l) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
  &-buttons {
    @include media($media_m-l) {
      .TariffItemPanel-link {
        margin-bottom: rem-calc(5);
      }
    }

    @include media($media_l) {
      margin-top: auto;
      width: 100%;
      .Button {
        display: block;
      }
    }
  }
  &-subline {
    margin-top: rem-calc(5);
  }
  &-name {
    margin-left: auto;
    .Icon-base { fill: $color-orange; }
    svg { height: rem-calc(54); }
    @include media($media_l) {
      padding-left: $global-padding*0.5;
      text-align: right;
      .TextHeadline {
         margin-top: rem-calc(-5);
      }
    }
  }
  &-singlePaymentFee {
    text-align: right;
    .TextCopy {
      font-size: .8rem;
      line-height: 1.2rem;
    }

    @include media($media_s) {
      width: 50%;
    }
    @include media($media_s-m) {
      flex-shrink: 0;
      margin-left: auto;
    }
    @include media($media_l) {
      margin-top: $global-padding  * .25;
    }
  }
  &-paymentFee {
    text-align: right;
    @include media($media_s-m) {
      width: 40%;
      flex-shrink: 0;
    }
  }
  &--inverted {
    @include media($media_s) {
      .TariffItemPanel-header {
        padding-bottom: 0;
        min-height: initial;
      }
    }
    @include media($media_m-l) {
      .TariffItemPanel-header {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 1px;
          bottom: 0;
          left: $global-padding;
          right: $global-padding;
          @include borderDashed($color:$color-gray50, $bottom: true);
        }
      }
    }
  }

  .Callout--badge {
    position: relative;
    transform: scale(0.75);
    transform-origin: left bottom;
    margin-left: $global-padding;

    @include media($media_m) {
      position: absolute;
      right:0;
      top: auto;
      bottom: 0;
      transform: scale(0.75) translateY(50%);
      transform-origin: center top;
    }
    @include media($media_l) {
      position: absolute;
      right: 24%;
      top: 0;
      bottom: auto;
      transform: scale(0.75) translateY(50%);
      transform-origin: center top;
    }
  }
}
